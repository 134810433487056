// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/logo.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".indexcontainer{background:#fff;padding:10px 0 10px 0;height:auto}.indexcontainer .logo{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat;width:20%;height:40px;margin:0 auto 0 auto}.indexcontainer .header{width:100%;height:auto;margin:0 auto 0 auto}.indexcontainer .title{text-transform:uppercase;color:#f90139;font-size:2.6em;line-height:1em;text-align:center;width:50%;margin:10px auto}.indexcontainer .text{color:#4c5d6a;font-size:1em;text-align:center;padding:0 30px}.indexcontainer .text.noheader{margin:0}.indexcontainer .text a,.indexcontainer .text span{color:#688194;text-decoration:underline}", ""]);
// Exports
module.exports = exports;
