<template>
  <Layout>
    <div class="indexcontainer">
      <div class="logo" />
      <h1 class="title">
        Compliance
      </h1>
      <div class="text noheader">
        Uh oh... it looks like you took a wrong turn...<br><br>Compliance
        modules can be accessed only through our apps.<br><br>
        Check out <a href="https://outfit7.com">outfit7.com</a> instead.
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  mounted() {

  },
}
</script>

<style lang="scss">
.indexcontainer {
  background: #fff;
  padding: 10px 0 10px 0;
  height: auto;

  .logo {
    background-image: url('../../assets/images/logo.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 20%;
    height: 40px;
    margin: 0 auto 0 auto;
  }
  .header {
    width: 100%;
    height: auto;
    margin: 0 auto 0 auto;
  }
  .title {
    text-transform: uppercase;
    color: #f90139;
    font-size: 2.6em;
    line-height: 1em;
    text-align: center;
    width: 50%;
    margin: 10px auto;
  }
  .text {
    color: #4c5d6a;
    font-size: 1em;
    text-align: center;
    padding: 0 30px;
    &.noheader {
      margin: 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    a {
      color: #688194;
      text-decoration: underline;
    }
  }
}
</style>
